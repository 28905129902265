import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import HeaderLight from "../../components/header/headerLight/headerLight";

import "./style.scss";

const ProtectionDonnesPerso = (location) => {

  return (
    <>
      <Helmet>
        <body className="protection-donnees-personnelles"/>
      </Helmet>
      <Layout>
        <SEO title="Protection des données personnelles"/>
        <HeaderLight
          title={
            <>Protection des <br/> <strong>données personnelles</strong>
            </>
          }
          location={location}
          crumbLabel="Protection des données personnelles"
        />
        <div className="protection-donnees-content">

          <section>
            <h2>Préambule</h2>
            <p>
              L’Auxiliaire et L’Auxiliaire Vie sont particulièrement attachées au respect de vos droits et notamment à la protection des données personnelles.
              <br/><br/>
              C’est pourquoi nous nous engageons à respecter dans toutes ses composantes le Règlement Général Européen sur la Protection des Données Personnelles (RGPD).
              <br/><br/>
              La présente politique expose les engagements pris par notre société pour une utilisation raisonnable des données à caractère personnel.
            </p>
          </section>

          <section>
            <h2>Définitions</h2>
            <p>
              <li>
                Commission Nationale de l’Informatique et des Libertés (CNIL)
              </li>
              <br/>Il s’agit de l’autorité compétente en matière de protection des données personnelles, créée en 1978. Elle accompagne les particuliers dans la maitrise de leurs données personnelles.
              <br/><br/>
              <li>
                Délégué à la protection des données (DPD)
              </li>
              <br/>Il s’agit de l’interlocuteur privilégié à contacter si vous souhaitez exercer vos droits ou pour toute question relative à la protection des données personnelles au sein de L’Auxiliaire.
              <br/><br/>
              <li>
                Données à caractère personnel
              </li>
              <br/>Toute donnée permettant d’identifier directement ou indirectement une personne physique.
              <br/><br/>
              <li>
                Données sensibles
              </li>
              <br/>Information concernant l’origine raciale ou ethnique, les opinions politiques, philosophiques ou religieuses, l’appartenance syndicale, la santé ou la vie sexuelle.
              <br/><br/>
              <li>
                Règlement général européen sur la protection des données personnelles (RGPD)
              </li>
              <br/>Le RGPD a pour vocation essentielle de renforcer la protection et la confidentialité des données à caractère personnel au bénéfice de chaque personne résidant dans l’Union Européenne.
              <br/><br/>
              <li>
                Responsable de traitement
              </li>
              <br/>Entité juridique en charge de déterminer les finalités de traitement et des moyens mis en œuvre.
            </p>
          </section>

          <section>
            <h2>Cadre d'application de la politique</h2>
            <p>
              La politique de protection des données s’applique à l’ensemble des personnes physiques en lien avec L’Auxiliaire et L’Auxiliaire Vie (désignés ci-après L’Auxiliaire). Cela concerne donc les clients, collaborateurs, sous-traitants, personnes intéressées à un contrat, prospects, utilisateurs du site internet mais aussi aux candidats répondant à une offre d’emploi.
            </p>
          </section>

          <section>
            <h2>Principes de la politique</h2>
            <p>
              <strong>
                1. Finalité déterminée, explicite et légitime du traitement des données
              </strong>
              <br/><br/>
              Toutes les données collectées par L’Auxiliaire doivent avoir une finalité précise. Les données collectées ne peuvent pas être exploitées de manière incompatible avec ce principe.
              <br/><br/>
              Ces données doivent être collectées loyalement, en informant les personnes concernées.
              <br/><br/>
              Les finalités poursuivies sont :
              <br/><br/>
              <li>La passation, la gestion et l’exécution de contrats d’assurance,</li>
              <li>La gestion des clients et la prospection commerciale,</li>
              <li>L’exécution des dispositions légales, réglementaires et administratives en vigueur, notamment la lutte contre la fraude, la lutte contre le blanchiment et le financement du terrorisme,</li>
              <li>Les études statistiques, enquêtes et sondages,</li>
              <li>Le fonctionnement des instances,</li>
              <li>La mise en place d’actions de prévention,</li>
              <li>La gestion des instruments financiers,</li>
              <li>La gestion du recrutement.</li>
            </p>
            <p>
              <strong>
                2. Proportion et pertinence des données collectées
              </strong>
              <br/><br/>
              Les données collectées sont pertinentes et strictement nécessaires à l’objectif poursuivi par la collecte. Lors d’une souscription à un contrat, nous pouvons par exemple être amené à collecter des données relatives à votre identification (état civil, adresse…), relatives à votre situation familiale, patrimoniale et financière.
            </p>
            <p>
              <strong>
                3. Transmission des données
              </strong>
              <br/><br/>
              Dans le strict respect des finalités énoncées, L’Auxiliaire peut transmettre vos données aux destinataires suivants :
              <br/><br/>
              <li>Le personnel habilité de L’Auxiliaire ;</li>
              <li>Le personnel habilité des entités de la SGAM btp (groupe prudentiel auquel L’Auxiliaire est affiliée),</li>
              <li>Les délégataires de gestion, les intermédiaires d’assurance, les partenaires, les prestataires, les sociétés d’assistance,</li>
              <li>S’il y a lieu, les coassureurs et réassureurs de L’Auxiliaire ainsi que les organismes professionnels et les fonds de garantie,</li>
              <li>Les personnes intéressées à un contrat telles que les bénéficiaires, les victimes, les témoins et les tiers,</li>
              <li>Les personnes intervenant à un contrat telles que les avocats, les experts, enquêteurs et professionnels de santé, médecins conseils et leurs personnels habilités,</li>
              <li>Les juridictions et autorités compétentes conformément aux lois et réglementations applicables.</li>
            </p>
            <p>
              <strong>
                4. Durée de conservation limitées des données à caractère personnel
              </strong>
              <br/><br/>
              Les données à caractère personnel ne peuvent être conservées que pour une durée limitée. Cette durée est déterminée en fonction de la finalité du traitement et de règles issues de recommandations de la Commission National de l’Informatique et des Libertés (CNIL).
              <br/><br/>
              Ainsi, à titre d’exemple, les données nécessaires à la gestion de votre contrat d’assurance sont conservées pendant toute la durée de votre contrat, puis jusqu’à l’expiration des prescriptions applicables au-delà de la durée du contrat.
            </p>
            <p>
              <strong>
                5. Obligation de consentement
              </strong>
              <br/><br/>
              En fonction des finalités poursuivies, L’Auxiliaire peut être amené à recueillir votre consentement express.
              <br/><br/>
              Cependant, lorsque nous utilisons vos données personnelles aux fins d’exécution d’un contrat, répondre à une demande de devis, respecter une obligation légale, votre consentement n’est pas requis.
            </p>
            <p>
              <strong>
                6. Prospection commerciale
              </strong>
              <br/><br/>
              En matière de prospection commerciale par voie électronique, si vous êtes client de L’Auxiliaire, nous pouvons vous proposer d’améliorer votre couverture d’assurance avec des produits complémentaires ou analogues à ceux souscrits et adaptés à vos besoins.
              <br/><br/>
              Si vous n’êtes pas client de L’Auxiliaire, nous ne pourrons proposer des offres adaptées à vos besoins qu’à condition d’avoir recueilli votre consentement.
            </p>
            <p>
              <strong>
                7. Confidentialité / sécurité des données
              </strong>
              <br/><br/>
              Afin de prévenir toute violation de données et garantir la confidentialité des données collectées, L’Auxiliaire a mis en œuvre une Politique de Protection des Systèmes d’Informations (PSSI). Cette politique est mise en œuvre et adaptée à la nature des données traitées.
              <br/><br/>
              Par ailleurs, des mesures de sécurité physique des locaux ont été déployées.
              <br/><br/>
              L’Auxiliaire exige également de ses sous-traitants qu’ils présentent des garanties appropriées pour assurer la sécurité et la confidentialité des données personnelles.
              <br/><br/>
              En cas de violation de données, notre société est dans l’obligation d’en notifier sans délai à la CNIL et de vous informer dans les meilleurs délais en cas de violation de données susceptible d’engendrer un risque élevé pour vos droits et libertés.
            </p>
            <p>
              <strong>
                8. Confidentialité des données de santé
              </strong>
              <br/><br/>
              À noter que s’agissant des données de santé, compte tenu de leur caractère sensible, leur traitement est soumis à l’obtention de votre consentement (signature d’un contrat).
            </p>
            <p>
              <strong>
                9. Droits des personnes
              </strong>
              <br/><br/>
              Toute personne dispose de droits sur les données le concernant. Ces droits peuvent être exercés à tout moment et gratuitement, sous condition de justifier de son identité.
              <br/><br/>
              <li>Droit d’accès, de rectification et d’effacement de vos données : vous pouvez obtenir une copie des informations vous concernant, les mettre à jour et les effacer,</li>
              <li>Droit d’opposition : vous pouvez vous opposer au traitement de vos données pour un motif légitime ou sans motif lorsque vos données sont utilisées à des fins de prospection commerciale,</li>
              <li>Droit de retrait du consentement : vous pouvez retirer votre consentement lorsque le traitement repose sur le recueil préalable de celui-ci,</li>
              <li>Droit à la limitation du traitement : vous pouvez demander de geler temporairement l’utilisation de certaines de vos données.</li>
              <br/><br/>
              Les demandes sont à envoyer par courrier électronique à {" "}
              <a href="mailto:contactdpd@auxiliaire.fr">contactdpd@auxiliaire.fr</a> {" "}
              ou par courrier postal : {" "}
              <strong>
                L’AUXILIAIRE, Délégué à la Protection des Données, 20 rue Garibaldi 69413 Lyon.
              </strong>
            </p>
            <p>
              <strong>
                10. Suivi de la politique de protection des données personnelles
              </strong>
              <br/><br/>
              Cette politique est accessible sur le site internet de L'Auxiliaire.
              <br/><br/>
              Elle peut être actualisée à tout moment pour prendre en compte les évolutions législatives et réglementaires ou encore un changement d’organisation.
            </p>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ProtectionDonnesPerso;